(function($) {

	/*----------  Reference slider op de homepage  ----------*/
	sliderReference = function() {
		$('.references__slider').slick({
			dots: false,
			rows: 0,
			infinite: true,
			slidesToShow: 1,
			arrows: true,
			adaptiveHeight: false,
			nextArrow: '<div class="slick-arrow slick-right icon-arrow-right"></div>',
			prevArrow: '<div class="slick-arrow slick-left icon-arrow-left"></div>',
			autoplay: true,
			autoplaySpeed: 7000,
			fade: true,
			slidesToScroll: 1,
			cssEase: 'linear'
		});

		calculateReferenceHeight();
	};

	calculateReferenceHeight = function() {
		var windowsize = $(window).width();
		var referenceHeight = $('.home__references').outerHeight(true);

		if (windowsize > 767) {
			$('.home__title').css('margin-bottom', (referenceHeight / 2) + 50);
			$('.home__left').css('padding-top', referenceHeight / 2);
		} else {
			$('.home__title').css('margin-bottom', '30px');
			$('.home__left').css('padding-top', '0');
		}
	};

	$(window).resize(calculateReferenceHeight);

})(jQuery); // Fully reference jQuery after this point.