/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

 	/*----------  Detect if touch device  ----------*/
 	isTouchDevice = function() {
 		return 'ontouchstart' in document.documentElement;
 	};

 	/*----------  Responsive video`s  ----------*/
 	fluidVideo = function() {
 		$('.wp_content iframe[src*="youtube"]').wrap('<div class="videoWrapper"></div>');
 		$('.wp_content iframe[src*="vimeo"]').wrap('<div class="videoWrapper"></div>');
 	};

 	/*----------  Open image url`s in popup  ----------*/
 	function showImageModal() {
 		$('.wp_content a').each(function(i, el) {
 			if(! $(this).parents().hasClass('gallery--wrapper')) {
 				var href_value = el.href;
 				if (/\.(jpg|png|gif)$/.test(href_value)) {
 					$(this).magnificPopup({
 						type: 'image',
 						closeOnContentClick: true,
 						mainClass: 'mfp-img-mobile',
 						image: {
 							verticalFit: true
 						}
 					});
 				} 
 			}
 		});

 		$('.gallery--wrapper ul').each(function(i, el) {
 			$(this).magnificPopup({
 				delegate: 'a', /* the selector for gallery item */
 				type: 'image',
 				gallery: {
 					enabled:true,
 					arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir% mfp-prevent-close"><span class="mfp-prevent-close btn btn--primary btn--%dir%"></span></button>'
 				}
 			});
 		});
 	}

 	/*----------  Float labels  ----------*/
 	floatLabels = function() {
 		new FloatLabels( '.gform_wrapper',{
 			customEvent  : null,
 			customLabel  : null,
 			exclude      : '.no-label',
 			inputRegex   : /email|number|password|search|tel|text|url/,
		    prioritize   : 'label', // label|placeholder
		    requiredClass: 'required',
		    style        : 2, // 0|1|2
		    transform    : 'input,select,textarea'
		});

		new FloatLabels( '.comment-form',{
			customEvent  : null,
			customLabel  : null,
			exclude      : '.no-label',
			inputRegex   : /email|number|password|search|tel|text|url/,
			prioritize   : 'label', // label|placeholder
			requiredClass: 'required',
			style        : 2, // 0|1|2
			transform    : 'input,select,textarea'
		});
 	};

 	/*----------  On scroll  ----------*/
 	$(window).scroll(function() {
 		stickyNavigation();
 	});

 	/*----------  On resize  ----------*/
 	var waitForFinalEvent = (function () {
 		var timers = {};
 		return function (callback, ms, uniqueId) {
 			if (!uniqueId) {
 				uniqueId = "Don't call this twice without a uniqueId";
 			}
 			if (timers[uniqueId]) {
 				clearTimeout (timers[uniqueId]);
 			}
 			timers[uniqueId] = setTimeout(callback, ms);
 		};
 	})();

 	$(window).resize(function() {
 		waitForFinalEvent(function(){
 			if (isTouchDevice()) {
 				$('body').removeClass('device--notouch');
 				$('body').addClass('device--touch');
 			} else {
 				$('body').removeClass('device--touch');
 				$('body').addClass('device--notouch');
 			}
 		}, 500);
 	});

 	jQuery(document).bind('gform_post_render', function(){
		floatLabels();
	});

  	// Use this variable to set up the common and page specific functions. If you
  	// rename this variable, you will also need to rename the namespace below.
  	var Sage = {
  		'common': {
  			init: function() {

  				/*----------  Navigation  ----------*/
  				stickyNavigation();
  				toggleNavigation();
  				toggleSubNav();
  				scrollTo();

  				/*----------  Sliders  ----------*/
  				sliderReference(); 

  				/*----------  WP Styles  ----------*/
  				fluidVideo(); // Responsive video
  				showImageModal();
  				floatLabels();
  				
  				$('p').each(function() { // Remove empty p`s
  					var $this = $(this);
  					if($this.html().replace(/\s|&nbsp;/g, '').length == 0)
  					$this.remove(); 
  				}); 


  				/*----------  Add body class on touch devices  ----------*/
  				if (isTouchDevice()) {
  					$('body').removeClass('device--notouch');
  					$('body').addClass('device--touch');
  				} else {
  					$('body').removeClass('device--touch');
  					$('body').addClass('device--notouch');
  				}

  				/*----------  Replace image errors on development  ----------*/
  				if(window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('development') > -1) {
  					$('.project, .item__image, .impression__image').each(function(index, el) {
  						var project = $(this);
  						var img = new Image();
  						img.src = el.style.backgroundImage.replace('url(','').replace(')','').replace(/\"/gi, "");

  						$(img).error(function(){
  							project.css('background-image', 'url(http://placehold.it/1000x600?text=Afbeelding+niet+beschikbaar)');
  						});
  					});

  					$('img').error(function(){
  						$(this).attr('src', 'http://placehold.it/1000x600?text=Afbeelding+niet+beschikbaar');
  					});
  				}
  			},
  			finalize: function() {
  			}
  		}
  	};

  	var UTIL = {
  		fire: function(func, funcname, args) {
  			var fire;
  			var namespace = Sage;
  			funcname = (funcname === undefined) ? 'init' : funcname;
  			fire = func !== '';
  			fire = fire && namespace[func];
  			fire = fire && typeof namespace[func][funcname] === 'function';

  			if (fire) {
  				namespace[func][funcname](args);
  			}
  		},
  		loadEvents: function() {
  			UTIL.fire('common');

  			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
  				UTIL.fire(classnm);
  				UTIL.fire(classnm, 'finalize');
  			});

  			UTIL.fire('common', 'finalize');
  		}
  	};

  	$(document).ready(UTIL.loadEvents);

  })(jQuery);
