(function($) {

	var slideWrapper = $(".impressions"),
		iframes = slideWrapper.find('.impression--player'),
		lazyImages = slideWrapper.find('.impression__image'),
		lazyCounter = 0,
		iframeWidth = 16;
		iframeHeight = 9;
		iframeRatio = iframeWidth/iframeHeight;

	scrollTo = function() {
 		$('.scroll--impression').on('click', function(e){
 			e.preventDefault();

 			var $target = $($(this).attr('href'));
 			var $header = $('.header');
 			$('html, body').animate({
 				scrollTop: $target.offset().top - $header.outerHeight(true) + 'px'
 			}, 300);
 		});
 	};
	
	impressionSlider = function() {
		slideWrapper.slick({
			dots: false,
			infinite: true,
			arrows: true,
			adaptiveHeight: false,
			nextArrow: '<div class="slick-arrow slick-right icon-arrow-right"></div>',
			prevArrow: '<div class="slick-arrow slick-left icon-arrow-left"></div>',
			autoplay: false,
			speed: 1000,
			rows: 0,
			fade: true,
			slidesToShow: 1,
			slidesToScroll: 1
		});
	};

	// POST commands to YouTube or Vimeo API
	function postMessageToPlayer(player, command){
		if (player == null || command == null) return;
		player.contentWindow.postMessage(JSON.stringify(command), "*");
	}

	// When the slide is changing
	function playPauseVideo(slick, control){
		var currentSlide, slideType, startTime, player, video, iframeWidth, iframeHeight;

		currentSlide = slick.find(".slick-current");
		currentItem = currentSlide.find('.impression__item');
		slideTypeClass = currentSlide.attr("class").split(' ')[1];
		slideType = slideTypeClass.split('impression--')[1];
		player = currentSlide.find("iframe").get(0);
		startTime = currentSlide.data("video-start");

		console.log(slideType);

		if (slideType === "vimeo") {
			switch (control) {
				case "play":
				if ((startTime != null && startTime > 0 ) && !currentSlide.hasClass('started')) {
					currentSlide.addClass('started');
					postMessageToPlayer(player, {
						"method": "setCurrentTime",
						"value" : startTime
					});
				}
				postMessageToPlayer(player, {
					"method": "play",
					"value" : 1
				});
				break;
				case "pause":
				postMessageToPlayer(player, {
					"method": "pause",
					"value": 1
				});
				break;
			}
		} else if (slideType === "youtube") {
			switch (control) {
				case "play":
				postMessageToPlayer(player, {
					"event": "command",
					"func": "mute"
				});
				postMessageToPlayer(player, {
					"event": "command",
					"func": "playVideo"
				});
				break;
				case "pause":
				postMessageToPlayer(player, {
					"event": "command",
					"func": "pauseVideo"
				});
				break;
			}
		} else if (slideType === "video") {
			video = currentSlide.children("video").get(0);
			if (video != null) {
				if (control === "play"){
					video.play();
				} else {
					video.pause();
				}
			}
		}
	}

	// Resize player
	function resizePlayer(iframes, ratio) {
		if (!iframes[0]) return;
		var win = $(".impression__video"),
		width = win.width(),
		playerWidth,
		height = win.height(),
		playerHeight;
		ratio = ratio || 16/9;

		iframes.each(function(){
			var current = $(this);
			if (width / ratio < height) {
				playerWidth = Math.ceil(height * ratio);
				current.width(playerWidth).height(height).css({
					left: (width - playerWidth) / 2,
					top: 0
				});
			} else {
				playerHeight = Math.ceil(width / ratio);
				current.width(width).height(playerHeight).css({
					left: 0,
					top: (height - playerHeight) / 2
				});
			}
		});
	}

	// DOM Ready
	$(function() {

		slideWrapper.on("init", function(slick){
			slick = $(slick.currentTarget);
			setTimeout(function(){
				playPauseVideo(slick,"play");
			}, 1000);

			iframeWidth = slick.find("iframe").attr('width');
			iframeHeight = slick.find("iframe").attr('height');
			resizePlayer(iframes, iframeRatio);
		});
		slideWrapper.on("beforeChange", function(event, slick) {
			slick = $(slick.$slider);
			iframeWidth = slick.find(".slick-current").find("iframe").attr('width');
			iframeHeight = slick.find(".slick-current").find("iframe").attr('height');
			resizePlayer(iframes, iframeRatio);
			playPauseVideo(slick,"pause");
		});
		slideWrapper.on("afterChange", function(event, slick) {
			slick = $(slick.$slider);
			playPauseVideo(slick,"play");
			iframeWidth = slick.find(".slick-current").find("iframe").attr('width');
			iframeHeight = slick.find(".slick-current").find("iframe").attr('height');
			resizePlayer(iframes, iframeRatio);
		});
		slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
			lazyCounter++;
			if (lazyCounter === lazyImages.length){
				lazyImages.addClass('show');
			}
		});

		//start the slider
		impressionSlider();
	});

	// Resize event
	var resizeTimer;
	$(window).on("resize.slickVideoPlayer", function(){  
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {
			// resizing has "stopped"
			iframeWidth = $(".slick-current").find("iframe").attr('width');
			iframeHeight = $(".slick-current").find("iframe").attr('height');
			resizePlayer(iframes, iframeRatio);
		}, 250);
	});

})(jQuery); // Fully reference jQuery after this point.
