(function($) {

	toggleNavigation = function() {
		$('.navigation--trigger').on('click', function(e){
			e.preventDefault();
			if($('body').hasClass('navigation--open')) {
				$('body').removeClass('navigation--open');
			}
			else {
				$('body').addClass('navigation--open');
			}
		}); 
	};

	/*----------  Open and close subnavigation on touch devices  ----------*/
	toggleSubNav = function() {
		$(".submenu-trigger").on('click', function(e){
			e.stopPropagation();
			e.preventDefault();
			if($(this).parent('.menu-item').hasClass('open-subnav')) {
				$(this).parent('.menu-item').removeClass('open-subnav');
			}
			else {
				$('.menu-item').removeClass('open-subnav');
				$(this).parent('.menu-item').addClass('open-subnav');
			}
		}); 
	};

	stickyNavigation = function() {
		var scrollTop = 0;

		if ($(window).width() > 767 && $(window).width() < 1024) {
			scrollTop = 49;
		} else if ($(window).width() > 1023) {
			scrollTop = 60;
		} else {
			scrollTop = 0;
		}

		if ($(this).scrollTop() >= scrollTop ){  
			$('body').addClass('header--sticky');
		}
		else {
			$('body').removeClass('header--sticky');
		}
	};

})(jQuery); // Fully reference jQuery after this point.
